import React, { Component } from 'react';
import './About.css';

/**
 * Class to handle the rendering of the Home page.
 * @extends React.Component
 */
export default class Home extends Component {
  render() {
    return (
      <div className="About container">
        <h1>Acerca de</h1>
        <p>
          Polysort es un aplicativo que permite identificar residuos y
          clasificarlos de acuerdo a categorías basado en las normatividades
          colombianas, especialmente GTC 24 y resolución 2184 de 2019.
        </p>
        <img src="/images/logo-icipc.svg" alt="ICIPC Logo" />
      </div>
    );
  }
}
