/* eslint-disable */

export const GTC24 = {
  0: {
    name: "Ordinarios",
    color: "success",
    realColor: "green",
    description: "Residuos ordinarios, no reciclables",
    icon: "circle",
  },
  1: {
    name: "Papel",
    color: "secondary",
    realColor: "gray",
    description: "Papel y cartón",
    icon: "recycle",
  },
  2: {
    name: "Plástico",
    color: "primary",
    realColor: "blue",
    description: "Plásticos",
    icon: "recycle",
  },
  3: {
    name: "Vidrio",
    color: "light",
    realColor: "snow",
    description: "Vidrios",
    icon: "recycle",
  },
  4: {
    name: "Orgánicos",
    color: "light",
    realColor: "beige",
    description: "Residuos Orgánicos",
    icon: "recycle",
  },
  5: {
    name: "Residuos Peligrosos",
    color: "danger",
    realColor: "red",
    description: "Residuos peligrosos, implementos de hospital",
    icon: "biohazard",
  },
  6: {
    name: "Aluminio",
    color: "warning",
    realColor: "yellow",
    description: "Aluminio o metales",
    icon: "recycle",
  },
  7: {
    name: "Reciclable",
    color: "primary",
    realColor: "blue",
    description: "Reciclables (plástico, vidrio, aluminio)",
    icon: "recycle",
  },
};

export const RES2184 = {
  0: {
    name: "Residuos orgánicos",
    color: "success",
    realColor: "green",
    description: "Residuos orgánicos aprovechables",
    icon: "circle",
  },
  1: {
    name: "Residuos aprovechables",
    color: "light",
    realColor: "white",
    description:
      "Residuos aprovechables como plástico, vidrio, metales, multicapa, papel y cartón",
    icon: "recycle",
  },
  2: {
    name: "Residuos no aprovechables",
    color: "dark",
    realColor: "black",
    description: "Residuos no aprovechables",
    icon: "recycle",
  },
};
